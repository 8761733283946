import {Injectable, OnDestroy} from '@angular/core';
import {Observable, Subject} from 'rxjs';
import {DiseaseService} from '@matchsource/store/diseases';
import {map, takeUntil} from 'rxjs/operators';
import orderBy from 'lodash-es/orderBy';

export interface DiseaseListModel {
  name: string;
  code: string;
  description: string;
}

@Injectable()
export class DiseaseListService implements OnDestroy {
  private readonly destroy$ = new Subject<void>();

  public readonly data$: Observable<DiseaseListModel[]>;

  constructor(private readonly diseaseService: DiseaseService) {
    this.data$ = this.diseaseService.diseases$.pipe(
      map(diseases => Object.values(diseases)),
      map(diseases => orderBy(diseases, 'description', 'asc')),
      map(diseases =>
        diseases.map(disease => ({
          name: `${disease.code} - ${disease.description}`,
          code: disease.code,
          description: disease.description,
        }))
      ),
      takeUntil(this.destroy$)
    );
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
